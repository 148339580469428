import React from "react";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import Personalization from "../../Personalization/Personalization";
import PropTypes from "prop-types";
import {graphql} from "gatsby";

const RichTextTwoCol = ({ block }) => {
  const title = block.title;
  const leftText = block.leftTextColumn;
  const rightText = block.rightTextColumn;
  const visibleFor = block.visibleFor;

  return (
    <Personalization visibleFor={visibleFor}>
      <Section>
        {title && <p className="text7 mb-7">{title}</p>}
        <div className="row">
          <div className="col m-6 sm-12">
            <StructuredTextRenderer
              data={leftText}
              isParagraphClassName="mb-7 subtle"
              isHeadingClassName="mb-5"
            />
          </div>
          <div className="col m-6 sm-12">
            <StructuredTextRenderer
              data={rightText}
              isParagraphClassName="mb-7 subtle"
              isHeadingClassName="mb-5"
            />
          </div>
        </div>
      </Section>
    </Personalization>
  );
};

export default RichTextTwoCol;

RichTextTwoCol.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string,
    leftTextColumn: PropTypes.object.isRequired,
    rightTextColumn: PropTypes.object.isRequired,
    visibleFor: PropTypes.string,
  }),
};

export const query = graphql`
  fragment RichTextTwoCol on DatoCmsRichtexttwocolumn {
    id: originalId
    model {
      apiKey
    }
    visibleFor
    title
    leftTextColumn {
      value
      blocks {
        __typename
        ...Link
        ...VideoPlayer
        ... on DatoCmsImage {
          id: originalId
          image {
            gatsbyImageData
            alt
            title
          }
        }
      }
    }
    rightTextColumn {
      value
      blocks {
        __typename
        ...Link
        ...VideoPlayer
        ... on DatoCmsImage {
          id: originalId
          image {
            gatsbyImageData
            alt
            title
          }
        }
      }
    }
  }
`